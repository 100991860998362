<template>
  <div class="main-container">
    <div v-if="!walletAddressRef">
      <div class="connect-wallet-alert-div">
        <el-alert
          class="connect-wallet-alert"
          :closable="false"
          effect="dark"
          type="warning"
          show-icon
        >
          <h2>Please connect your wallet to see your tokens</h2>
        </el-alert>
      </div>
    </div>
    <div v-if="walletAddressRef" class="main-container">
      <div v-if="hasTokens" v-infinite-scroll="loadMore">
        <el-row>
          <el-col
            v-for="tokenData in tokensData"
            :key="tokenData.tokenID"
            class="grow"
            :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="6"
          >
            <router-link
              class="el-link el-link--default"
              :to="`/tokens/${tokenData.projectSlug}/${tokenData.tokenID}`"
              style="width: 100% !important"
            >
              <el-card
                style="margin: 5%; width: 100% !important"
                :body-style="{ padding: '10px' }"
              >
                <el-image
                  :src="imageFileName(tokenData)"
                  class="image"
                  fit="contain"
                  style="width: 100%; height: auto; aspect-ratio: 1"
                >
                  <template #error>
                    <div
                      style="
                        width: 100%;
                        height: 0;
                        padding-top: 40%;
                        padding-bottom: 60%;
                        font-size: 20px;
                      "
                    >
                      <el-icon :size="50" style="width: 3em; height: 3em">
                        <full-screen style="width: 5em; height: 5em" />
                      </el-icon>
                      <br />Loading...
                    </div>
                  </template>
                </el-image>
                <div style="padding: 14px">
                  <span>{{ tokenData.name }}</span>
                  <div v-if="tokenData.projectSlug === 'norte'">
                    <NorteTokenCard :tokenData="tokenData" />
                  </div>
                </div>
              </el-card>
            </router-link>
          </el-col>
        </el-row>
        <div class="infinitescrolldiv"></div>
      </div>
      <div v-if="!hasTokens && !loading">
        <el-result icon="warning" title="You have no StudioUno tokens yet.">
          <template #extra>
            <router-link class="el-link el-link--default" :to="'/norte'">
              <el-button type="primary" size="default">
                Create & Mint your first one with Norte
              </el-button>
            </router-link>
          </template>
        </el-result>
      </div>
      <div class="loading" v-loading="loading"></div>
    </div>
  </div>
</template>

<script>
  import { onBeforeMount, ref, watch, defineComponent, computed } from 'vue'
  import { useStore } from 'vuex'
  import { FullScreen } from '@element-plus/icons'
  import { MODES } from '@/constants/toolPanelConstants'
  import { getUserTokens } from '@/services/TokenService'
  import { getAllContractConfigs } from '@/services/ProjectService'
  import NorteTokenCard from '@/components/projects/norte/norte-gallery/token-card'

  // Number of items per page
  const PAGINATION_OFFSET = 10000

  export default defineComponent({
    name: 'Gallery',
    components: {
      FullScreen,
      NorteTokenCard,
    },

    setup() {
      const store = useStore()
      const {
        dispatch,
        state: { myGallery, user },
      } = store

      // Read from state
      const walletAddress = computed(() => user.walletAddress)
      const tokensData = computed(() => myGallery.myTokens)
      const currentPage = computed(() => myGallery.myTokensPage)

      const walletAddressRef = ref(walletAddress)
      const loading = ref(false)
      const hasTokens = ref(!!tokensData.value.length)
      const reachedToEndPage = ref(false)
      const validContractConfigs = ref(null)

      const loadTokens = async (isInitial = false) => {
        loading.value = true

        try {
          if (!validContractConfigs.value) {
            validContractConfigs.value = getAllContractConfigs()
          }

          const tokens = await getUserTokens(
            validContractConfigs.value,
            walletAddressRef.value,
            currentPage.value,
            PAGINATION_OFFSET
          )

          const sortedTokens = tokens.sort((a, b) =>
            a.tokenID > b.tokenID ? 1 : -1
          )

          if (tokens.length > 0) {
            hasTokens.value = true

            const data = sortedTokens.map((token) => ({
              tokenID: token.tokenID,
              projectSlug: token.projectSlug,
              name: token.name,
              image: token.image,
              mode: token.mode || '1',
              style: token.style || '1',
            }))

            if (isInitial) {
              dispatch('setMyTokens', data)
            } else {
              dispatch('pushMyTokens', data)
            }
          } else {
            reachedToEndPage.value = true
          }
        } catch (e) {
          console.error(e)
        }

        loading.value = false
      }

      const loadMore = async () => {
        if (!loading.value) {
          if (!reachedToEndPage.value) {
            dispatch('setMyTokensPage', currentPage.value + 1)
            await loadTokens()
          }
        }
      }

      const retrieveTokens = async () => {
        if (walletAddressRef.value) {
          dispatch('resetMyTokens')
          hasTokens.value = false
          reachedToEndPage.value = false
          await loadTokens(true)
        }
      }

      const imageFileName = (tokenData) => {
        let fileName = tokenData.image
        if (
          tokenData.projectSlug == 'frases' &&
          !fileName.includes('thumbnail')
        ) {
          fileName = fileName.replace(/(\.[\w\d_-]+)$/i, '-thumbnail$1')
        }
        return fileName
      }

      watch([walletAddressRef], async () => {
        await retrieveTokens()
      })

      onBeforeMount(async () => {
        await retrieveTokens()
      })

      return {
        hasTokens,
        loading,
        walletAddressRef,
        MODES,
        tokensData,
        loadMore,
        imageFileName,
      }
    },
  })
</script>
<style scoped>
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
  .loading {
    height: 40px;
    margin: 40px;
  }
  .grow {
    transition: all 0.2s ease-in-out !important;
  }
  .grow:hover {
    transform: scale(1.03) !important;
  }
</style>
